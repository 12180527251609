import { Backdrop, CircularProgress } from '@mui/material';
import { useCheckVersion, useEnvironmentChange, useLogout, useSnackbar } from 'app/hooks';
import { ErrorBoundary } from 'react-error-boundary';
import { Switch } from 'react-router-dom';
import { copyText } from 'src/app/internationalization/copyText';
import AmountProvider from 'src/legacy/components/Amount/Provider';
import InteriorView from 'src/legacy/routes/Interior/InteriorView';

import { ApiTermsOfUse } from './components/APITermsOfUse/APITermsOfUse';
import { AuthorizationLoader } from './components/AuthorizationLoader';
import { Navbar } from './components/Navbar';
import { SentryRoute } from './components/Routes';
import { useStartUp } from './components/StartUp';
import { useStartUpLoader } from './hooks/useStartUpLoader';

const {
  common: { whiteScreenError },
} = copyText;

const ErrorFallback = () => {
  return null;
};

export const App = (): JSX.Element => {
  useStartUp();
  useLogout();
  useEnvironmentChange();
  useCheckVersion();
  const isStartUpLoading = useStartUpLoader();

  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });

  return (
    <>
      <Backdrop open={isStartUpLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <ApiTermsOfUse />
      <AmountProvider>
        <AuthorizationLoader>
          <Navbar>
            <Switch>
              <SentryRoute path="/">
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(err, stack) => {
                    enqueueSnackbar(<p>{whiteScreenError}</p>, {
                      variant: 'error',
                      persist: true,
                      preventDuplicate: true,
                    });
                    // eslint-disable-next-line no-console
                    console.error(err);
                    // eslint-disable-next-line no-console
                    console.info(stack);
                  }}
                >
                  <InteriorView />
                </ErrorBoundary>
              </SentryRoute>
            </Switch>
          </Navbar>
        </AuthorizationLoader>
      </AmountProvider>
    </>
  );
};
