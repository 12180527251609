import { Auth } from 'aws-amplify';
import { store } from 'src/store';

import HTTP from '../httpImpls/fetch';
import Sessions from '../internal/Sessions';
import { DriveWealthError, DriveWealthSessionError } from './Error';

/**
 * @function request
 * @description Normally, it is unnecessary to use this function and this is for internal use only.
 *  Call request() if you need to make a custom API call that is not covered by another function.
 * @param {object} options
 * @param {string} [options.method]
 * @param {string} options.endpoint
 * @param {string} [options.sessionKey]
 * @param {string | Object} [options.body]
 * @param {Object.<string, string>} [options.addlHeaders]
 * @param {string} [''] options.externalUrl
 * @param {boolean} [false] options.noEnv
 * @param {boolean} [false] options.noAuth
 * @returns {Promise.<{body: string | Object | boolean, statusCode: number, headers: Object<string, string>}>}
 */
export default function request({
  method = 'GET',
  endpoint,
  sessionKey,
  body,
  addlHeaders = {},
  externalUrl,
  noEnv = false,
  noAuth = false,
}) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(data => {
        if (!sessionKey) {
          const savedSessionKey = Sessions.get();
          if (savedSessionKey) sessionKey = savedSessionKey;
        }

        const headers = {
          Accept: 'application/json',
        };
        const shouldDefaultContentTypeToJSON =
          method === 'POST' || method === 'PUT' || method === 'PATCH';

        if (shouldDefaultContentTypeToJSON && !addlHeaders['Content-type']) {
          headers['Content-Type'] = 'application/json';
        }

        if (!noEnv && !store.getState().settings?.ENV) {
          const unsub = store.subscribe(() => {
            if (!store.getState().settings?.ENV) {
              return;
            }
            unsub();

            if (
              (!addlHeaders || addlHeaders['z-datasrc'] !== null) &&
              store.getState().settings.ENV
            ) {
              // if no datasrc header was explicitly passed in, add one. ONLY do this if an ENV
              // setting is actually established.
              headers['z-datasrc'] = store.getState().settings.ENV;
            }
            for (const header in addlHeaders) {
              if (!addlHeaders[header] || addlHeaders[header] === 'form-data') continue;
              headers[header] = addlHeaders[header];
            }
            headers['z-auth-token'] = data.idToken.jwtToken;
            if (!noAuth) headers['authorization'] = `Bearer ${data.accessToken.jwtToken}`;

            if (!endpoint.includes('/api')) {
              endpoint = '/api' + endpoint;
            }

            const isParseableOctetStream =
              headers['Content-Type'] === 'application/octet-stream' && typeof body === 'object';

            if (headers['Content-Type'] === 'application/json' || isParseableOctetStream) {
              body = JSON.stringify(body);
            }

            if (addlHeaders && addlHeaders['Content-Type']) {
              if (!body) throw new Error('No body provided, but content-type header was set.');
              if (
                addlHeaders['Content-Type'] === 'application/x-www-form-urlencoded' &&
                body instanceof Object &&
                body.constructor === Object
              ) {
                const mappableObj = body;
                body = Object.keys(mappableObj)
                  .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(mappableObj[key]))
                  .join('&');
              }
            }
            HTTP(
              method,
              externalUrl || endpoint,
              headers,
              body,
              (statusCode, resHeaders, resBody) => {
                // eslint-disable-line arrow-body-style
                const contentType = resHeaders['Content-Type'] || resHeaders['content-type'] || '';
                if (resBody && contentType.indexOf('application/json') !== -1) {
                  try {
                    resBody = JSON.parse(resBody);
                  } catch (err) {
                    // resBody will remain as is
                  }
                }

                if (String(statusCode)[0] === '2' || String(statusCode)[0] === '3') {
                  return resolve({
                    body: resBody,
                    statusCode,
                    headers: resHeaders,
                  });
                }

                let errorMessage = 'No error message was returned from the server.';
                let errorCode = '-1';
                if (resBody) {
                  if (resBody.error) {
                    errorMessage = resBody.error.message;
                    errorCode = resBody.error.errorCode;
                  }

                  if (resBody.message && resBody.errorCode) {
                    errorMessage = resBody.message;
                    errorCode = resBody.errorCode;
                  }

                  if (resBody.verificationResponse) {
                    errorMessage = resBody.verificationResponse.message;
                    errorCode = '-1';
                  }
                }

                if (statusCode === 504) {
                  errorMessage = resBody.message;
                  errorCode = '-1';
                }

                if (statusCode === 401) {
                  console.log('401 error in request.js, signing out - 146');
                  Auth.signOut();
                  return reject(
                    new DriveWealthSessionError(
                      errorMessage,
                      resBody,
                      statusCode,
                      resHeaders,
                      errorCode,
                    ),
                  );
                }

                // Redirect if server middleware detects an invalid IP
                const INVALID_IP_MSG = 'Invalid IP';
                if (statusCode === 403 && resBody && resBody.message === INVALID_IP_MSG) {
                  console.log('403 error in request.js, signing out - 162');
                  Auth.signOut();
                  return reject(
                    new DriveWealthSessionError(
                      errorMessage,
                      resBody,
                      statusCode,
                      resHeaders,
                      errorCode,
                    ),
                  );
                }

                return reject(
                  new DriveWealthError(errorMessage, resBody, statusCode, resHeaders, errorCode),
                );
              },
            );
          });
        } else {
          if (
            (!addlHeaders || addlHeaders['z-datasrc'] !== null) &&
            store.getState().settings.ENV
          ) {
            // if no datasrc header was explicitly passed in, add one. ONLY do this if an ENV
            // setting is actually established.
            headers['z-datasrc'] = store.getState().settings.ENV;
          }
          for (const header in addlHeaders) {
            if (!addlHeaders[header] || addlHeaders[header] === 'form-data') continue;
            headers[header] = addlHeaders[header];
          }
          headers['z-auth-token'] = data.idToken.jwtToken;
          if (!noAuth) headers['authorization'] = `Bearer ${data.accessToken.jwtToken}`;

          if (!endpoint.includes('/api')) {
            endpoint = '/api' + endpoint;
          }

          const isParseableOctetStream =
            headers['Content-Type'] === 'application/octet-stream' && typeof body === 'object';

          if (headers['Content-Type'] === 'application/json' || isParseableOctetStream) {
            body = JSON.stringify(body);
          }

          if (addlHeaders && addlHeaders['Content-Type']) {
            if (!body) throw new Error('No body provided, but content-type header was set.');
            if (
              addlHeaders['Content-Type'] === 'application/x-www-form-urlencoded' &&
              body instanceof Object &&
              body.constructor === Object
            ) {
              const mappableObj = body;
              body = Object.keys(mappableObj)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(mappableObj[key]))
                .join('&');
            }
          }

          HTTP(
            method,
            externalUrl || endpoint,
            headers,
            body,
            (statusCode, resHeaders, resBody) => {
              // eslint-disable-line arrow-body-style
              const contentType = resHeaders['Content-Type'] || resHeaders['content-type'] || '';
              if (resBody && contentType.indexOf('application/json') !== -1) {
                try {
                  resBody = JSON.parse(resBody);
                } catch (err) {
                  // resBody will remain as is
                }
              }

              if (String(statusCode)[0] === '2' || String(statusCode)[0] === '3') {
                return resolve({
                  body: resBody,
                  statusCode,
                  headers: resHeaders,
                });
              }

              let errorMessage = 'No error message was returned from the server.';
              let errorCode = '-1';
              if (resBody) {
                if (resBody.error) {
                  errorMessage = resBody.error.message;
                  errorCode = resBody.error.errorCode;
                }

                if (resBody.message && resBody.errorCode) {
                  errorMessage = resBody.message;
                  errorCode = resBody.errorCode;
                }

                if (resBody.verificationResponse) {
                  errorMessage = resBody.verificationResponse.message;
                  errorCode = '-1';
                }
              }

              if (statusCode === 504) {
                errorMessage = resBody.message;
                errorCode = '-1';
              }

              if (statusCode === 401) {
                console.log('401 error in request.js, signing out - 271');
                Auth.signOut();
                return reject(
                  new DriveWealthSessionError(
                    errorMessage,
                    resBody,
                    statusCode,
                    resHeaders,
                    errorCode,
                  ),
                );
              }

              // Redirect if server middleware detects an invalid IP
              const INVALID_IP_MSG = 'Invalid IP';
              if (statusCode === 403 && resBody && resBody.message === INVALID_IP_MSG) {
                console.log('403 error in request.js, signing out - 287');
                Auth.signOut();
                return reject(
                  new DriveWealthSessionError(
                    errorMessage,
                    resBody,
                    statusCode,
                    resHeaders,
                    errorCode,
                  ),
                );
              }

              return reject(
                new DriveWealthError(errorMessage, resBody, statusCode, resHeaders, errorCode),
              );
            },
          );
        }
      })
      .catch(e => {
        reject(e);
      });
  });
}
