import { Auth } from 'aws-amplify';
import { isString, isUndefined } from 'lodash';
import { setSetting } from 'modules/settings';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmountPromise } from 'react-use';
import { Environments, useEnvironment, useEnvironments, useSnackbar } from 'src/app/hooks';
import { useGoogleAnalytics } from 'src/app/hooks/useGoogleAnalytics';

import { useWhoAmI } from '../../hooks/useWhoAmI';
import { useUpdateUserRolePrivileges } from '../SecLending/hooks/useUpdateUserRolePrivileges';

import { copyText } from 'src/app/internationalization/copyText';

const { startUp: { whoamiError } } = copyText;

export const useStartUp = (): void => {
  const environments = useEnvironments();
  const env = useEnvironment();
  const { data: whoAmIData, isError: isWhoAmIError, error } = useWhoAmI();
  const { mutate: updateUserRolePrivileges } = useUpdateUserRolePrivileges();
  
  const dispatch = useDispatch();
  const mounted = useUnmountPromise();
  const environmentValue = env?.value;
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: false });

  useGoogleAnalytics();

  useEffect(() => {
    const updatePrivs = async () => {
      const data = await mounted(Auth.currentSession());
      const idToken = data.getIdToken().getJwtToken();

      if (isString(environmentValue)) {
        updateUserRolePrivileges({
          authToken: idToken,
          dataSrc: environmentValue,
        });
      }
    };

    if (environmentValue) {
      updatePrivs();
    }
  }, [environmentValue, mounted, updateUserRolePrivileges]);

  useEffect(() => {
    if (!isUndefined(whoAmIData)) {
      let nextDataSrc = environmentValue;

      // Go through the environments passed in through env vars and the default
      if (!nextDataSrc) {
        const markedDefault = Object.entries(environments).find(
          ([, currentEnv]) => currentEnv.DEFAULT,
        );

        if (markedDefault) {
          nextDataSrc = markedDefault[1].value;
        } else {
          nextDataSrc = Object.entries(environments)[0][1].value;
        }
      }

      // If no permission groups exist for the default environment, instead take the first entry found that the user does have permission for
      if (!whoAmIData.role.datasrcPermissionGroups[nextDataSrc]) {
        nextDataSrc = Object.keys(whoAmIData.role.datasrcPermissionGroups)[0] as Environments;
      }

      dispatch(setSetting('ENV', nextDataSrc));
    }
  }, [dispatch, environmentValue, environments, whoAmIData]);

  useEffect(() => {
    // Show a toast, but don't log out. We can attempt to recover on a refresh or whatnot
    if (isWhoAmIError) {
      console.log('who am I error', error)
      enqueueSnackbar(whoamiError, { variant: 'error',  persist: true });
    }
  }, [isWhoAmIError, enqueueSnackbar, error]);
};
