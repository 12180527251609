import { HubCapsule } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';
import { Hub } from 'aws-amplify';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUnsetUser } from './useAuth';

/**
 * Watches Cognito for any sign out events and clears local storage
 */
export const useLogout = (): void => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const unsetUser = useUnsetUser();

  useEffect(() => {
    const authListener = (data: HubCapsule) => {
      console.log('cognito auth hub event', data);
      if (data.payload.event === 'signOut') {
        history.push('/');
        queryClient.clear();
        unsetUser();
      }
    };

    Hub.listen('auth', authListener);

    return () => {
      Hub.remove('auth', authListener);
    };
  }, [history, queryClient, unsetUser]);
};
